<template>
  <div class="Conditions">
    <div class="Conditions__container">
      <p v-html="conditions"></p>
    </div>
  </div>
</template>

<script>
  import {
    COMMUNITY_FETCH_CONDITIONS,
  } from '@/store/community/info.module';
  import { mapGetters } from 'vuex';
  import store from '@/store';


  export default {
    name: 'ConditionsPage',
    computed: {
      ...mapGetters([
        'theme',
        'isAuthenticated',
        'authenticationError',
        'loadingAuth'
      ]),
    },
    data(){
      return{
        conditions:"",
      }
    },
    methods: {
      fetchConditions() {
        store.dispatch( COMMUNITY_FETCH_CONDITIONS)
          .then( async ( data ) => {
            this.conditions = data.conditions
          } )
      },
    },
    async mounted(){
      this.fetchConditions()
    },
}
</script>

<style lang="scss" scoped>
.Conditions{
  width: 100%;
  &__container{
  padding             : 20px 20px 20px 60px;
  display             : flex;
  flex-direction      : column;
  align-items         : flex-start;
  overflow-y: scroll;
  max-height: calc(100vh - 136px);
}
}
</style>
